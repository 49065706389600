.wrapper {
    position: relative;
    box-sizing: border-box;
    overflow-y: scroll;
    background-color: var(--box-background);
    border-radius: var(--radius);
    font-weight: 500;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

    header {
        margin: 0 0 24px;
        display: flex;
        align-items: center;
        height: 32px;
    }

    footer {
        margin: 24px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
    }

    > * {
        box-sizing: border-box;
    }

    > section {
        margin: 0;
    }

    > button + button {
        margin-top: 6px;
    }
}

.wrapper.pad-none {
    padding: 0;
}

.wrapper.pad-s {
    padding: 6px;
}

.wrapper.pad-m {
    padding: 12px;
}

.wrapper.pad-l {
    padding: 24px;
}
