.backdrop {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.3);
    backdrop-filter: blur(1px);
    opacity: 1;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backdropActive {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;
}

.content {
    position: relative;
    box-sizing: border-box;
    overflow-y: scroll;
    box-shadow:
        rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    padding: 24px;
    background-color: var(--box-background);
    border-radius: 4px;
    margin: 0 auto !important;
    width: 80%;
    max-width: 600px;
    font-family: polymath, sans-serif;
    font-weight: 500;
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;

    header {
        margin: 0 0 24px;
        display: flex;
        align-items: center;
        height: 32px;
    }

    footer {
        margin: 24px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
    }

    > * {
        box-sizing: border-box;
    }

    > section {
        margin: 0;
    }

    h1 {
        font-size: 22px;
        font-weight: 700;
    }

    h2 {
        margin: 0;
        font-size: 22px;
        font-weight: 700;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
    }

    h5 {
        font-size: 14px;
        font-weight: 700;
    }

    h6 {
        font-size: 16.8px;
        font-weight: 700;
    }

    textarea {
        box-sizing: border-box;
    }

    code {
        overflow: hidden;
        display: block;
        width: 100%;
        white-space: wrap;
        word-break: break-all;
    }
}

.contentActive {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 150ms;
    transition-duration: 350ms;
}

.closeButton {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;

    svg path {
        fill: var(--text);
    }
}
