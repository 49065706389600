.wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    opacity: 0.6;

    svg path {
        fill: var(--text-light);
        transition: fill 0.3s ease;
    }
}

.active {
    opacity: 1;

    svg path {
        fill: var(--green);
    }
}
