.wrap {
    position: relative;
    margin: 0;
    border: 1px solid var(--grey);
    border-radius: 4px;
    width: 18px;
    height: 18px;
    padding: 0;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
        content: '';
    }
}

.background {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 4px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: translate3d(-50%, -50%, 0) scale(0);
    transition: transform 0.3s ease;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: auto;
    transform: translate3d(-50%, -50%, 0) scale(0);
    transition: transform 0.5s ease-in-out;

    path {
        fill: white;
        stroke: white;
        stroke-width: 2px;
    }
}

.checkbox {
    opacity: 0;
}

.wrap.checked {
    &::after {
        animation: firework 0.5s ease forwards 0.1s;
    }

    .background {
        transform: translate3d(-50%, -50%, 0) scale(1);
    }

    .checkmark {
        transform: translate3d(-50%, -50%, 0) scale(1);
    }
}

@keyframes firework {
    0% {
        opacity: 1;
        box-shadow:
            0 0 0 -2px,
            0 0 0 -2px,
            0 0 0 -2px,
            0 0 0 -2px,
            0 0 0 -2px,
            0 0 0 -2px;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        box-shadow:
            0 -17px 0 0px,
            16px -10px 0 0px,
            16px 10px 0 0px,
            0 17px 0 0px,
            -16px 10px 0 0px,
            -16px -10px 0 0px;
    }
}

@keyframes jelly {
    0% {
        transform: scale(1, 1);
    }
    30% {
        transform: scale(1.25, 0.75);
    }
    40% {
        transform: scale(0.75, 1.25);
    }
    50% {
        transform: scale(1.15, 0.85);
    }
    65% {
        transform: scale(0.95, 1.05);
    }
    75% {
        transform: scale(1.05, 0.95);
    }
    100% {
        transform: scale(1, 1);
    }
}
