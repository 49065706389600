@import '../../styles/breakpoints.scss';

.wrap {
    z-index: 8000;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    height: 100px;
    background-color: var(--box-background);
    transform: translate3d(0, -56px, 0);
    transition: all 0.3s ease;

    @include md {
        height: 64px;
        transform: translate3d(0, -64px, 0);
    }
}

.content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;

    @include md {
        height: 64px;
    }
}

.nav {
    position: absolute;
    top: 100%;
    left: 50%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0;
    height: 44px;
    padding: 0;
    transform: translate3d(-50%, 0, 0);

    @include md {
        top: 0;
        height: 64px;
    }
}

.navItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;

    &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 80%;
        height: 0;
        background: var(--brand1);
        transform: translate3d(-50%, 0, 0);
        content: '';
        transition: height 0.3s ease;
    }
}

.active {
    font-weight: 700;

    &::before {
        height: 3px;
    }
}

.showMenu {
    transform: translate3d(0, 0, 0);
}

.scrollOnTop::before {
    opacity: 0;
}
