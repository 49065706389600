.wrap {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    svg {
        width: 100px;
        height: auto;
    }
}

.center {
    margin: 0 auto 24px;
}
