.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 24px;

    & + .wrap {
        border-top: 1px solid var(--grey);
    }

    &:last-of-type {
        border-bottom: 1px solid var(--grey);
    }
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    background: none;
    cursor: pointer;

    span {
        display: block;
        width: 12px;
        height: 12px;
        border: 1px solid var(--grey);
        border-radius: var(--radius);
    }

    &.PLANNED span {
        background: var(--light-grey);
    }
}

.text {
    flex-grow: 1;
    line-height: 1.33;
    text-align: left;
    cursor: pointer;
}

.text.IDLE {
    opacity: 0.6;
}
.text.DONE {
    opacity: 1;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    background: none;
    opacity: 0.8;
    cursor: pointer;

    svg {
        width: 16px;

        path {
            fill: var(--text);
        }
    }
}
