.wrap {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    gap: 12px;

    input {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    p {
        margin: 0;
    }
}

.label,
.leftLabel {
    text-align: left;
    opacity: 0.5;
}

.label.on,
.leftLabel.on {
    opacity: 1;
}

.icon {
    position: relative;
    border-radius: var(--radius);
    width: 54px;
    height: 28px;
    background-color: var(--grey);
    transition: background-color 0.3s ease;

    > span {
        position: absolute;
        top: 50%;
        left: 4px;
        border-radius: 8px;
        width: 22px;
        height: 22px;
        background-color: var(--white);
        transform: translate3d(0, -50%, 0);
        transition: all 0.3s ease;
    }
}

.icon.on {
    background-color: var(--brand1);

    > span {
        transform: translate3d(24px, -50%, 0);
    }
}

.icon.s {
}

.icon.m {
}

.icon.l {
}
