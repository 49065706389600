.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.texts {
    overflow: hidden;
    position: relative;
    margin: 24px 0 0;
    width: 100%;
    height: 18px;

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }

    p {
        margin: 4px 0 8px;
        font-size: 12px;
        line-height: 10px;
        text-align: center;
    }
}

:global {
    .loader-animation {
        & :local {
            animation: moveText 60s ease forwards;
        }
    }
}

@keyframes moveText {
    0% {
        transform: translate3d(0, 0, 0);
    }
    9% {
        transform: translate3d(0, 0, 0);
    }
    10% {
        transform: translate3d(0, -10%, 0);
    }
    19% {
        transform: translate3d(0, -10%, 0);
    }
    20% {
        transform: translate3d(0, -20%, 0);
    }
    29% {
        transform: translate3d(0, -20%, 0);
    }
    30% {
        transform: translate3d(0, -30%, 0);
    }
    39% {
        transform: translate3d(0, -30%, 0);
    }
    40% {
        transform: translate3d(0, -40%, 0);
    }
    49% {
        transform: translate3d(0, -40%, 0);
    }
    50% {
        transform: translate3d(0, -50%, 0);
    }
    59% {
        transform: translate3d(0, -50%, 0);
    }
    60% {
        transform: translate3d(0, -60%, 0);
    }
    69% {
        transform: translate3d(0, -60%, 0);
    }
    70% {
        transform: translate3d(0, -70%, 0);
    }
    79% {
        transform: translate3d(0, -70%, 0);
    }
    80% {
        transform: translate3d(0, -80%, 0);
    }
    89% {
        transform: translate3d(0, -80%, 0);
    }
    90% {
        transform: translate3d(0, -90%, 0);
    }
    99% {
        transform: translate3d(0, -90%, 0);
    }
    100% {
        transform: translate3d(0, -90%, 0);
    }
}
