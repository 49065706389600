.loaderWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.list {
    display: grid;
    grid-template-columns: 1fr;
}

.grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.loader {
    background: linear-gradient(
        120deg,
        #f0f0f0 25%,
        var(--background) 50%,
        #f0f0f0 75%
    );
    background-size: 200% 100%;
    animation: loading 2s infinite;
}

.squared {
    border-radius: 10px;
}

.rounded {
    border-radius: 50%;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
