@import 'src/layout/styles/breakpoints.scss';

.wrap {
    display: grid;
    gap: 32px;
}

.list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
}

.grid {
    grid-template-columns: 1fr;

    @include md {
        grid-template-columns: repeat(3, 1fr);
    }
}
