.wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 32px;

    input {
        outline: none;
        border: 1px solid var(--grey);
        border-radius: var(--radius);
        padding: 8px;
        background: none;
        color: var(--text);
    }

    textarea {
        outline: none;
        border: 1px solid var(--grey);
        border-radius: var(--radius);
        padding: 8px;
        background: none;
        color: var(--text);
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        background: none;
        cursor: pointer;

        svg path {
            fill: var(--text);
        }
    }
}
