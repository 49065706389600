.wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    width: 100%;
}

.columns-1 {
    grid-template-columns: repeat(1, 1fr);
}

.columns-2 {
    grid-template-columns: repeat(2, 1fr);
}

.columns-3 {
    grid-template-columns: repeat(3, 1fr);
}

.columns-4 {
    grid-template-columns: repeat(4, 1fr);
}

.columns-5 {
    grid-template-columns: repeat(5, 1fr);
}

.columns-7_5 {
    grid-template-columns: 7fr 5fr;
}

.columns-5_7 {
    grid-template-columns: 5fr 7fr;
}

.gap-0 {
    gap: 0px;
}

.gap-6 {
    gap: 6px;
}

.gap-12 {
    gap: 12px;
}

.gap-16 {
    gap: 16px;
}

.gap-24 {
    gap: 24px;
}

.gap-48 {
    gap: 48px;
}
