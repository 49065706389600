$breakpoint-xs: '380px';
$breakpoint-sm: '576px';
$breakpoint-md: '768px';
$breakpoint-lg: '992px';
$breakpoint-xl: '1600px';

@mixin xs {
    @media (min-width: #{$breakpoint-xs}) {
        @content;
    }
}

@mixin sm {
    @media (min-width: #{$breakpoint-sm}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$breakpoint-md}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$breakpoint-lg}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$breakpoint-xl}) {
        @content;
    }
}
