.wrap {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin: 0;
    border: none;
    border-radius: var(--radius);
    background: none;
    color: var(--text);
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s ease;
    white-space: nowrap;

    p,
    svg {
        z-index: 1;
        position: relative;
    }

    p {
        margin: 0;
    }

    svg path {
        fill: var(--text);
    }

    &::after {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: var(--radius);
        width: 100%;
        height: 100%;
        background: var(--text);
        opacity: 0;
        content: '';
        transition: opacity 0.3s ease;
        will-change: opacity;
    }

    &:hover::after {
        opacity: 0.1;
    }
}

.s {
    height: 28px;
    padding: 0 8px;
    font-size: 12px;
}

.m {
    height: 32px;
    padding: 0 16px;
}

.l {
    height: 36px;
    padding: 0 24px;
}

.xl {
    height: 44px;
    padding: 0 24px;
    font-size: 16px;
}

.xxl {
    height: 54px;
    padding: 0 24px;
    font-size: 18px;
}

.blank {
    border: none;
    background: none;

    svg path {
        fill: var(--text);
    }
}

.plain {
    &::before {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: var(--radius);
        width: 100%;
        height: 100%;
        background: var(--text);
        opacity: 0.1;
        content: '';
    }
}

.primary {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #ff512f;
    background: linear-gradient(90deg, #f09819, #ff512f);
    color: white;

    p {
        color: white;
    }
}

.disabled,
.wrap:disabled {
    opacity: 0.5;
    pointer-events: none;
}
