@import '../../styles/global.scss';
@import '../../styles/breakpoints.scss';
@import url('https://use.typekit.net/pbx7uun.css');

:root {
    --background: #fafafa;
    --text: #222222;
    --box-background: #ffffff;
    --grey: #d9d9d9;
    --light-grey: #b6b6b6;
    --brand1: #ff512f;
    --radius: 8px;
}

.mainWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 116px auto 16px;
    width: 100%;

    @include md {
        margin: 80px auto 16px;
    }
}
