body.dark-theme {
    --background: #191919;
    --text: #ffffff;
    --box-background: #1d1d1d;
    --grey: #363636;
    --light-grey: #b6b6b6;
}

.task-wrapper {
    margin: 6px 0;
    text-align: left;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: var(--radius);
    height: 32px;
    padding: 0;
    background: none;
    font-weight: 700;
    cursor: pointer;
}

.empty-state {
    padding: 16px 0;
}

small {
    opacity: 0.8;
}

textarea {
    color: var(--text);
    background-color: transparent;
}

.multi-add-task-popup {
    color: var(--text);
    background-color: var(--background);
}
