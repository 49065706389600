.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h2 {
        margin: 0;
    }
}
