.wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
    max-width: 1648px;
    padding: 0 24px;
    text-align: center;
}

.narrow {
    max-width: 608px;
}

.center {
    align-items: center;
}

.gap-0 {
    gap: 0;
}

.gap-16 {
    gap: 16px;
}
