.wrap {
    position: relative;
    margin: 0;
    border-radius: var(--radius);
    width: 100%;
    max-width: 560px;
    padding: 12px 0 0;
    background: var(--box-background);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-height: 1000px;
    transition: max-height 0.6s ease;
}

.hidden {
    overflow: hidden;
    max-height: 54px;

    .indicator svg {
        opacity: 1;
    }
}

.indicator {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: var(--radius) var(--radius) 0 0;
    width: 100%;
    height: 12px;
    content: attr(data-color);

    svg {
        width: 12px;
        height: 12px;
        transform: rotate(90deg);
        transform-origin: center center;
        opacity: 0;
        transition: opacity 0.3s ease;
        will-change: opacity;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    border-bottom: 1px solid;
    height: 42px;
    padding: 0 24px;

    h2 {
        font-size: 14px;
        font-weight: 700;
        color: var(--text);
    }

    svg {
        cursor: pointer;
    }
}

.main {
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 24px;
}
