.wrap {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    > p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-size: 32px;
        font-weight: 800;
        line-height: 1;
        color: var(--brand1);
        transition: transform 0.3s ease;
    }

    > p:nth-child(1) {
        position: relative;
    }

    > p:nth-child(2) {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate3d(0, 100%, 0);
    }
}

.annual {
    > p:nth-child(1) {
        transform: translate3d(0, -100%, 0);
    }

    > p:nth-child(2) {
        transform: translate3d(0, 0, 0);
    }
}
