.wrap {
    box-sizing: border-box;
    padding: 0 0 16px;
    transition: all 0.3s ease;
}

.wrap.planned {
    max-height: 100px;
    padding: 0 0 16px;
}

.wrap.planned.checked {
    z-index: 2;
    max-height: 0;
    padding: 0;
}

.wrap.completed {
    max-height: 0;
    padding: 0;
}

.wrap.completed.checked {
    max-height: 100px;
    padding: 0 0 16px;
}

.content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: var(--radius);
    padding: 12px 24px;
    max-width: 560px;
    background: var(--box-background);
    text-align: left;
    transition: all 0.5s ease;
}

.content.planned {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.content.planned.checked {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
}

.content.completed {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
}

.content.completed.checked {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.indicator {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0 var(--radius) var(--radius) 0;
    width: 12px;
    height: 100%;
    content: attr(data-color);
    cursor: grab;

    svg {
        width: 12px;
    }
}
